import React from 'react';
import { Link } from 'gatsby';
import { Can } from '../auth/abilityContext';
import { NavItem } from 'reactstrap';

const HeaderLink = ({ link, title, subject }) => {
  return (
    <Can I="view" a={`${subject || title}`}>
      <NavItem>
        <Link to={link} activeClassName="active-link" className="nav-link">
          {title}
        </Link>
      </NavItem>
    </Can>
  );
};

export default HeaderLink;
