import React from 'react';
import Header from '../header';
import Footer from '../footer';

const Layout = props => {
  return (
    <div>
      <Header />
      <div className="container-fluid">
        <main>{props.children}</main>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
