import React, { useState, useContext } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from '../auth/protectedArea';
import {
  Navbar,
  NavbarToggler,
  Collapse,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import '../styles/header.css';
import { clearToken } from '../auth/getToken';
import HeaderLink from './headerLink';

const Divider = () => (
  <div className="nav-link">
    <div className="divider" />
  </div>
);

const Header = () => {
  const siteData = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const { name: userName } = useContext(UserContext).currentUser || {
    name: 'username',
  };

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const logout = () => {
    clearToken();
    window.location = '/';
  };

  return (
    <header className="border-bottom py-2">
      <Navbar light expand="md">
        <div className="container-fluid">
          <Link
            to="/"
            className="navbar-brand"
            style={{ whiteSpace: 'normal' }}
          >
            <span className="header-title">
              {siteData.site.siteMetadata.title}
            </span>
          </Link>
          <NavbarToggler onClick={toggle}>
            <FontAwesomeIcon icon={faEllipsisV} size="xs" />
          </NavbarToggler>
          <Collapse className=" justify-content-end" isOpen={isOpen} navbar>
            <Nav navbar>
              <HeaderLink link="/" title="Properties" />
              <HeaderLink link="/designs" title="Designs" />
              <HeaderLink link="/hardware/" title="Hardware" />
              <HeaderLink link="/users/" title="Users" />
              <Divider />
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  {userName}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={logout}>Logout</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </div>
      </Navbar>
    </header>
  );
};

export default Header;
