import React from 'react';

const Footer = () => {
  return (
    <footer className="py-3 d-flex justify-content-center border-top">
      © {new Date().getFullYear()}
    </footer>
  );
};

export default Footer;
